<template>
  <PageCard pageName="Edit User" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <v-wait for="fetchingUser">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div>
          <div class="row">
            <div class="col-sm-12 col-lg-8">
              <b-form-group
                label="First, Last Name"
                label-for="name"
                :label-cols="3"
                :horizontal="true"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-input
                      id="name"
                      type="text"
                      placeholder="First Name"
                      v-model="user.first_name"
                      :class="{
                        'is-invalid': $v.user.first_name.$error,
                        'is-valid': !$v.user.first_name.$invalid
                      }"
                      @blur="$v.user.first_name.$touch()"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.user.first_name.required"
                      >First Name can't be blank</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback v-if="!$v.user.first_name.alphaNum"
                      >First Name can be only alphanumeric
                      characters</b-form-invalid-feedback
                    >
                  </div>
                  <div class="col-sm-6">
                    <b-form-input
                      id="name"
                      type="text"
                      placeholder="Last Name"
                      v-model="user.last_name"
                      :class="{
                        'is-invalid': $v.user.last_name.$error,
                        'is-valid': !$v.user.last_name.$invalid
                      }"
                      @blur="$v.user.last_name.$touch()"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.user.last_name.required"
                      >Last Name can't be blank</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback v-if="!$v.user.last_name.alphaNum"
                      >Last Name can be only alphanumeric
                      characters</b-form-invalid-feedback
                    >
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                label="Email"
                label-for="email"
                :label-cols="3"
                :horizontal="true"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      v-bind:value="user.email"
                      readonly
                      :class="{
                        'is-invalid': $v.user.email.$error,
                        'is-valid': !$v.user.email.$invalid
                      }"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.user.email.required"
                      >Email can't be blank</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback v-if="!$v.user.email.email">
                      Please provide a valid email
                      address</b-form-invalid-feedback
                    >
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                label="Company"
                label-for="company"
                :label-cols="3"
                :horizontal="true"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-input
                      id="company"
                      type="text"
                      placeholder="Company"
                      v-model="user.company"
                      :class="{
                        'is-invalid': $v.user.company.$error,
                        'is-valid': !$v.user.company.$invalid
                      }"
                      @blur="$v.user.company.$touch()"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.user.company.required"
                      >Company can't be blank</b-form-invalid-feedback
                    >
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                label="Title"
                label-for="title"
                :label-cols="3"
                :horizontal="true"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-input
                      id="title"
                      type="text"
                      placeholder="Title"
                      v-model="user.title"
                      :class="{
                        'is-invalid': $v.user.title.$error,
                        'is-valid': !$v.user.title.$invalid
                      }"
                      @blur="$v.user.title.$touch()"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.user.title.required"
                      >Title can't be blank</b-form-invalid-feedback
                    >
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                label="Phone"
                label-for="phone"
                :label-cols="3"
                :horizontal="true"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <cleave
                      id="phone"
                      type="text"
                      placeholder="Phone number"
                      v-model="user.phone"
                      :options="phoneOptions"
                      class="form-control"
                      :class="{
                        'is-invalid': $v.user.phone.$error,
                        'is-valid': !$v.user.phone.$invalid
                      }"
                      @blur="$v.user.phone.$touch()"
                    ></cleave>
                    <b-form-invalid-feedback v-if="!$v.user.phone.required"
                      >Phone can't be blank</b-form-invalid-feedback
                    >
                    <b-form-invalid-feedback
                      v-if="!$v.user.phone.mustMatchPhoneLength"
                      >Phone number is not valid.</b-form-invalid-feedback
                    >
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                label="User Role"
                label-for="user_role"
                :label-cols="3"
                :horizontal="true"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-select
                      id="user_role"
                      v-model="user.role_id"
                      text-field="name"
                      value-field="id"
                      :options="roles"
                    >
                    </b-form-select>
                  </div>
                </div>
              </b-form-group>
              <div class="form-row form-group">
                <div
                  class="offset-3 col-9 text-danger pr-1"
                  v-if="superAdminSelected"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  This role is for FIN internal use only.
                </div>
              </div>
              <b-form-group
                label="Client Group"
                label-for="client_group"
                :label-cols="3"
                :horizontal="true"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-select
                      id="client_group"
                      v-model="clientGroup"
                      :options="clientGroups"
                      value-field="id"
                      text-field="displayName"
                      required
                    ></b-form-select>
                  </div>
                </div>
              </b-form-group>
              <div class="row" v-if="user.is_fin_api_enterprise">
                <div class="col-sm-3 col-form-label">
                  API Key
                </div>
                <div class="col-sm-6">
                  <button
                    class="btn btn-primary fw-btn "
                    @click="generateAPIKey()"
                    v-if="!keyAvailable"
                  >
                    Generate
                  </button>
                  <div v-if="keyAvailable">
                    {{ key }}
                  </div>
                </div>
              </div>

              <br />

              <h5>User Groups</h5>

              <b-card class="reports-card" body-class="p-1 pt-3">
                <div class="row align-items-center">
                  <div class="col-sm-5 pr-0">
                    <h6>Available Groups</h6>
                    <b-card
                      class="std-border mb-0 group-sub-card"
                      body-class="p-1"
                    >
                      <b-list-group
                        v-if="!this.availableGroups.length"
                        class="text-center"
                      >
                        <b-list-group-item
                          ><i>No Groups to list ... </i></b-list-group-item
                        >
                      </b-list-group>
                      <b-list-group v-else>
                        <div
                          v-for="(group, index) in availableGroups"
                          :key="index"
                        >
                          <b-list-group-item
                            class="p-1"
                            :class="[
                              { selected: group.id === selectedGroup.id },
                              'p-1'
                            ]"
                            @click="selectGroup(group)"
                          >
                            {{ group.displayName }}
                          </b-list-group-item>
                          <!-- - {{ group.group_type }} -->
                        </div>
                      </b-list-group>
                    </b-card>
                  </div>
                  <div class="col-sm-2">
                    <div class="group-control">
                      <div class="row text-center">
                        <div class="col-sm-12">
                          <b-button
                            size="sm"
                            variant="primary"
                            class="font-weight-bold"
                            :disabled="!selectedGroup.id"
                            @click="addGroup()"
                            v-html="'&nbsp;&nbsp;&#187;&nbsp;&nbsp;'"
                          ></b-button>
                        </div>
                      </div>
                      <div class="row text-center pt-1">
                        <div class="col-sm-12">
                          <b-button
                            size="sm"
                            variant="secondary"
                            class="font-weight-bold"
                            :disabled="!selectedGroup.id"
                            @click="removeGroup()"
                            v-html="'&nbsp;&nbsp;&#171;&nbsp;&nbsp;'"
                          ></b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-5 pl-0">
                    <h6>Selected Groups</h6>
                    <b-card
                      class="std-border mb-0 group-sub-card"
                      body-class="p-1"
                    >
                      <b-list-group
                        v-if="!this.selectedGroups.length"
                        class="text-center"
                      >
                        <b-list-group-item
                          ><i>No Groups selected ... </i></b-list-group-item
                        >
                      </b-list-group>
                      <b-list-group v-else>
                        <div
                          v-for="(group, index) in selectedGroups"
                          :key="index"
                        >
                          <b-list-group-item
                            class="p-1"
                            :class="[
                              { selected: group.id === selectedGroup.id },
                              'p-1'
                            ]"
                            @click="selectGroup(group)"
                          >
                            {{ group.displayName }}
                          </b-list-group-item>
                        </div>
                      </b-list-group>
                    </b-card>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <div slot="footer">
            <b-row class="">
              <b-col md="12">
                <router-link
                  tag="button"
                  :to="{ path: '/super_admin/all_users' }"
                  class="btn btn-secondary fw-btn mt-3 mr-3"
                >
                  Cancel
                </router-link>
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn btn-primary fw-btn mt-3 mx-3"
                  @click="editUser"
                  :disabled="$v.$invalid"
                  >Update User</b-button
                >
              </b-col>
            </b-row>
          </div>
        </div>
      </v-wait>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import { required, email, alphaNum } from 'vuelidate/lib/validators'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import store from '@/store/store'
import router from '@/router/index'
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AllUsers',
  components: {
    PageCard,
    Cleave
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']
    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.selectedUserId = this.$route.params.id
    this.fetchUserData()
  },
  data() {
    return {
      key: null,
      selectedUserId: null,
      roles: [],
      groups: [],
      clientGroups: [],
      selectedGroup: {},
      clientGroup: null,
      user: {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        company: null,
        title: null,
        phone: null,
        role_id: null,
        group_ids: [],
        is_fin_api_enterprise: false
      },
      phoneOptions: {
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-'
      }
    }
  },
  computed: {
    availableGroups() {
      return this.groups.filter(group => group.available === true)
    },
    selectedGroups() {
      return this.groups.filter(group => group.selected === true)
    },
    keyAvailable() {
      return this.key
    },
    superAdminSelected() {
      return this.user.role_id === 1 //FIN Admin
    }
  },
  methods: {
    addGroup() {
      let selectedGroup = this.groups.find(
        group => group.id === this.selectedGroup.id
      )
      selectedGroup.available = false
      selectedGroup.selected = true
    },
    removeGroup() {
      let selectedGroup = this.groups.find(
        group => group.id === this.selectedGroup.id
      )
      selectedGroup.available = true
      selectedGroup.selected = false
    },
    selectGroup(group) {
      this.selectedGroup = group
    },
    editUser() {
      if (this.user.phone.replaceAll('-', '').length > 11) {
        Vue.toasted.show('Please enter a valid Phone number', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      this.user.group_ids = [
        ...this.selectedGroups.map(group => group.id),
        this.clientGroup
      ]
      this.$http
        .patch(
          `/api/group_users/${this.user.id}`,
          { user: this.user },
          {
            handleErrors: true
          }
        )
        .then(res => {
          if (res.data.user) {
            Vue.toasted.show('User details updated successfully', {
              icon: 'user-circle',
              type: 'success'
            })
            this.$router.push('/super_admin/all_users')
          } else {
            let message =
              res.data.message ||
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
            Vue.toasted.show(message, {
              icon: 'chain-broken',
              type: 'error'
            })
          }
        })
    },
    fetchUserData() {
      this.$wait.start('fetchingUser')
      this.$http
        .get(`/api/group_users/${this.selectedUserId}/edit`, {
          params: { super_admin: true },
          handleErrors: true
        })
        .then(res => {
          this.user = res.data.user
          let cg = res.data.groups.find(
            group =>
              this.user.group_ids.includes(group.id) &&
              group.group_type !== 'User Group'
          )
          this.clientGroup = cg ? cg.id : null
          res.data.groups.forEach(group => {
            let isSelected = this.user.group_ids.includes(group.id)
            group.available = !isSelected
            group.selected = isSelected
          })
          res.data.groups.forEach(
            group => (group['displayName'] = group.name + ' [' + group.id + ']')
          )
          this.groups = res.data.groups.filter(group => {
            return group.group_type == 'User Group'
          })
          this.clientGroups = res.data.groups.filter(group => {
            return group.group_type != 'User Group'
          })
          this.roles = res.data.roles
        })
        .then(() => {
          if (this.user.is_fin_api_enterprise) {
            this.fetchFinAppEnterpriseKeyDetails()
          } else {
            this.$wait.end('fetchingUser')
          }
        })
    },
    fetchFinAppEnterpriseKeyDetails() {
      this.$http
        .get(`/api/super_admin/users/${this.selectedUserId}/get_user_api_key`, {
          params: { super_admin: true },
          handleErrors: true
        })
        .then(res => {
          this.key = res.data.key

          this.$wait.end('fetchingUser')
        })
    },
    generateAPIKey() {
      this.$http
        .post(`/api/super_admin/users/${this.selectedUserId}/set_fin_api_key`, {
          params: { super_admin: true },
          handleErrors: true
        })
        .then(res => {
          this.key = res.data.key
          if (res.data.key) {
            Vue.toasted.show('User API key generated and saved successfully', {
              icon: 'user-circle',
              type: 'success'
            })
          } else {
            let message =
              res.data.message ||
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
            Vue.toasted.show(message, {
              icon: 'chain-broken',
              type: 'error'
            })
          }
        })
    }
  },
  watch: {
    selectedGroups: function(newVal) {
      this.user.group_ids = newVal.map(group => group.id)
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      first_name: {
        required,
        alphaNum
      },
      last_name: {
        required,
        alphaNum
      },
      company: {
        required
      },
      title: {
        required
      },
      phone: {
        required,
        mustMatchPhoneLength(value) {
          if (value) {
            let phone = value.replaceAll('-', '')
            return phone.startsWith('1')
              ? phone.length === 11
              : phone.length === 10
          } else {
            return false
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.group-sub-card {
  height: 13rem;
  overflow-y: auto;
}

.list-group-item {
  border: none;
  cursor: pointer;

  &.selected {
    background-color: #f0f3f5;
  }
}
</style>
